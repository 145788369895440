import UilAngleDown from '@iconscout/react-unicons/icons/uil-angle-down';
import UilBell from '@iconscout/react-unicons/icons/uil-bell';
import UilDollarSign from '@iconscout/react-unicons/icons/uil-dollar-sign';
import UilSetting from '@iconscout/react-unicons/icons/uil-setting';
import UilSignout from '@iconscout/react-unicons/icons/uil-signout';
import UilUser from '@iconscout/react-unicons/icons/uil-user';
import UilExternalLink from '@iconscout/react-unicons/icons/uil-external-link-alt';
import UilUsersAlt from '@iconscout/react-unicons/icons/uil-users-alt';
import { Avatar } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
// import Search from './Search';
// import Message from './Message';
// import Notification from './Notification';
// import Settings from './settings';
import { Popover } from '../../popup/popup';
import Heading from '../../heading/heading';
import { logOut } from '../../../redux/authentication/actionCreator';
import { getTenantDomain } from '../../../utility/apiUtils';
import { tenantData } from '../../../jotaiStore/tenants';
import useWholesaleTenant from '../../../hooks/useWholesaler';

const AuthInfo = React.memo(() => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUser.data);
  const roles = currentUser?.tenantRoles?.map((i) => i.role?.toLowerCase()?.replace(' ', ''));
  const navigate = useNavigate();

  const [getTenant] = useAtom(tenantData);
  const isNotWholesaler = getTenant?.data?.type !== 'wholesale';
  const { isNotWholesaleSuperAdmin } = useWholesaleTenant();

  const SignOut = (e) => {
    e.preventDefault();
    dispatch(logOut(() => navigate('/')));
  };

  const userContent = (
    <div>
      <div className="min-w-[280px] sm:min-w-full pt-4">
        <figure className="flex items-center text-sm rounded-[8px] bg-section dark:bg-white10 py-[20px] px-[25px] mb-[12px]">
          {/* <img className="ltr:mr-4 rtl:ml-4" src={require('../../../static/img/avatar/chat-auth.png')} alt="" /> */}
          <Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />
          <figcaption>
            <Heading as="h5">
              {currentUser?.firstName} {currentUser?.lastName}
            </Heading>
            <p>{currentUser?.tenantRoles?.[0]?.role}</p>
          </figcaption>
        </figure>
        <ul className="mb-0">
          {isNotWholesaleSuperAdmin && (
            <li>
              <Link
                to="/network/client"
                className="inline-flex items-center hover:bg-shadow-transparent text-light dark:text-white60 dark:hover:text-white hover:text-primary dark:hover:bg-white10 dark:rounded-4 hover:pl-6 w-full px-2.5 py-3 text-sm transition-all ease-in-out delay-150"
              >
                <UilUser className="w-4 h-4 ltr:mr-3 rtl:ml-3" /> Network
              </Link>
            </li>
          )}
          <li>
            <Link
              to="/addUser"
              className="inline-flex items-center hover:bg-shadow-transparent text-light dark:text-white60 dark:hover:text-white hover:text-primary dark:hover:bg-white10 dark:rounded-4 hover:pl-6 w-full px-2.5 py-3 text-sm transition-all ease-in-out delay-150"
            >
              <UilUsersAlt className="w-4 h-4 ltr:mr-3 rtl:ml-3" /> Users
            </Link>
          </li>

          {roles?.includes('tenantadmin') || roles?.includes('superadmin') ? (
            <>
              <li>
                <Link
                  to="/transactions"
                  className="inline-flex items-center hover:bg-shadow-transparent text-light dark:text-white60 dark:hover:text-white hover:text-primary dark:hover:bg-white10 dark:rounded-4 hover:pl-6 w-full px-2.5 py-3 text-sm transition-all ease-in-out delay-150"
                >
                  <UilDollarSign className="w-4 h-4 ltr:mr-3 rtl:ml-3" /> Transactions
                </Link>
              </li>
              <li>
                <Link
                  to="/website-checkouts"
                  className="inline-flex items-center hover:bg-shadow-transparent text-light dark:text-white60 dark:hover:text-white hover:text-primary dark:hover:bg-white10 dark:rounded-4 hover:pl-6 w-full px-2.5 py-3 text-sm transition-all ease-in-out delay-150"
                >
                  <UilDollarSign className="w-4 h-4 ltr:mr-3 rtl:ml-3" /> Website Checkouts
                </Link>
              </li>
            </>
          ) : null}
          <li>
            <Link
              to="#"
              className="inline-flex items-center hover:bg-shadow-transparent text-light dark:text-white60 dark:hover:text-white hover:text-primary dark:hover:bg-white10 dark:rounded-4 hover:pl-6 w-full px-2.5 py-3 text-sm transition-all ease-in-out delay-150"
            >
              <UilBell className="w-4 h-4 ltr:mr-3 rtl:ml-3" /> Activity
            </Link>
          </li>
          {isNotWholesaler && (
            <>
              {getTenant?.data?.customerPortalEnabled && (
                <li>
                  <Link
                    to={`https://customer.${getTenantDomain()}.drugtestingsites.app`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center hover:bg-shadow-transparent text-light dark:text-white60 dark:hover:text-white hover:text-primary dark:hover:bg-white10 dark:rounded-4 hover:pl-6 w-full px-2.5 py-3 text-sm transition-all ease-in-out delay-150"
                  >
                    <UilExternalLink className="w-4 h-4 ltr:mr-3 rtl:ml-3" /> Customer Login
                  </Link>
                </li>
              )}

              <li>
                <Link
                  to="/donor/checkin"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center hover:bg-shadow-transparent text-light dark:text-white60 dark:hover:text-white hover:text-primary dark:hover:bg-white10 dark:rounded-4 hover:pl-6 w-full px-2.5 py-3 text-sm transition-all ease-in-out delay-150"
                >
                  <UilSetting className="w-4 h-4 ltr:mr-3 rtl:ml-3" /> Donor Check-In
                </Link>
              </li>
            </>
          )}
        </ul>
        <Link
          to="#"
          onClick={SignOut}
          className="flex items-center justify-center text-sm font-medium bg-[#f4f5f7] dark:bg-[#32333f] h-[50px] text-light hover:text-primary dark:hover:text-white60 dark:text-white87 mx-[-15px] mb-[-15px] rounded-b-6"
        >
          <UilSignout className="w-4 h-4 ltr:mr-3 rtl:ml-3" /> Sign Out
        </Link>
      </div>
    </div>
  );

  return (
    <div className="flex items-center justify-end flex-auto">
      {/* <div className="md:hidden">
        <Search />
      </div>
      <Message />
      <Notification />
      <Settings /> */}
      <div className="flex ltr:ml-3 rtl:mr-3 ltr:mr-4 rtl:ml-4 ssm:mr-0 ssm:rtl:ml-0">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="flex items-center text-light whitespace-nowrap">
            <Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />
            <span className="ltr:mr-1.5 rtl:ml-1.5 ltr:ml-2.5 rtl:mr-2.5 text-body dark:text-white60 text-sm font-medium md:hidden">
              {currentUser.name}
            </span>
            <UilAngleDown className="w-4 h-4 ltr:md:ml-[5px] rtl:md:mr-[5px]" />
          </Link>
        </Popover>
      </div>
    </div>
  );
});

export default AuthInfo;
