import {
  // notification,
  Spin,
} from 'antd';
import React, { lazy, Suspense, useEffect } from 'react';
// import { useAtom } from 'jotai';
import { Route, Routes, useLocation } from 'react-router-dom';

import withAdminLayout from '../../layout/withAdminLayout';
// import { socketIO } from '../../jotaiStore/socket';
import { PoolProvider } from '../../context/PoolContext';
// import { tenantData } from '@/jotaiStore/tenants';

// const Dashboard = lazy(() => import('../../container/dashboard'));
const DemoEight = lazy(() => import('../../container/dashboard/DemoEight'));
const Client = lazy(() => import('./client'));
const QuickBooks = lazy(() => import('./quickbooks'));
const Dashboard1 = lazy(() => import('./dashboard'));
const Donor = lazy(() => import('./donor'));
const Driver = lazy(() => import('./driver'));
const PoolRoutes = lazy(() => import('./pool'));
const AddUser = lazy(() => import('./addUser'));
const Sap = lazy(() => import('./sap'));
const NetworkClient = lazy(() => import('./networkClient'));
const HealthCare = lazy(() => import('./healthCare'));
const Transactions = lazy(() => import('./transactions'));
const WebsiteCheckouts = lazy(() => import('./websiteCheckouts'));
const NotFound = lazy(() => import('../../container/pages/404'));

const Admin = React.memo(() => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  // const [socketio] = useAtom(socketIO);
  // const [getTenant] = useAtom(tenantData);

  // useEffect(() => {
  //   if (socketio?.connected) {
  //     socketio.on('donorAdded', (payload) => {
  //       if (payload.tenantDomain !== getTenant?.data?.tenantDomain) return;
  //       notification.success({
  //         message: 'Thank You for check in',
  //         description: `Please drink enough water to produce sufficient required urine sample. If not ready please let the
  //                 front desk know you want to wait. Thank you for your visit`,
  //         className: 'custom-class',
  //         duration: 15,
  //         style: {
  //           width: 600,
  //         },
  //         onClose: () => {},
  //       });
  //     });
  //   }
  //   return () => {
  //     if (socketio?.connected) {
  //       socketio.off('donorAdded');
  //     }
  //   };
  // }, [socketio]);
  return (
    <Suspense
      fallback={
        <div className="spin flex items-center justify-center bg-white dark:bg-dark h-screen w-full fixed z-[999] ltr:left-0 rtl:right-0 top-0">
          <Spin />
        </div>
      }
    >
      <Routes>
        <Route index path="/*" element={<DemoEight />} />
        <Route index path="dashboard1/*" element={<Dashboard1 />} />
        <Route path="quickbooks/*" element={<QuickBooks />} />
        <Route path="donor/*" element={<Donor />} />
        <Route path="clients/*" element={<Client />} />
        <Route path="driver/*" element={<Driver />} />
        {/* <Route path="clearingHouse/*" element={<ClearingHouse />} /> */}
        <Route path="addUser/*" element={<AddUser />} />
        <Route path="sap/*" element={<Sap />} />
        <Route path="network/client/*" element={<NetworkClient />} />
        <Route
          path="pool/*"
          element={
            <PoolProvider>
              <PoolRoutes />
            </PoolProvider>
          }
        />
        <Route path="website-checkouts" element={<WebsiteCheckouts />} />
        <Route path="cases/*" element={<HealthCare />} />
        <Route path="transactions/*" element={<Transactions />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);
